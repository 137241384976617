<template>
  <div class="main">
    <div class="body-content">

      <div v-if="isLoading">Loading Voyage list...</div>
      <div v-else-if="error">Error loading Voyage list: {{ error }}</div>

      <div v-else>
        <div class="controls">
          <div class="search-container">
            <input v-model="searchQuery" placeholder="Search..." />
            <i class="fas fa-search search-icon"></i>
          </div>
          <h1 class="title">Voyage List</h1>
          <div class="actions">
            <div class="tooltip-container">
              <button class="add-button" @click="openAddOverlay" title="Add New Voyage"
                :disabled="isEditing || isAddingNew">
                <i class="fas fa-plus"></i>
              </button>
            </div>
            <div class="export-container">
              <button class="export-button" @click="toggleExportOptions" title="Export">
                <i class="fas fa-ellipsis-v"></i>
              </button>
              <ul v-if="showExportOptions" class="export-options">
                <li @click="exportToCSV">Export to CSV</li>
                <li @click="exportToPDF">Export to PDF</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="table-container">
          <table class="voyage-table table-design">
            <thead>
              <tr>
                <th>
                  <span>Voyage Name</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('Name')"></i>
                </th>
                <th>
                  <span>Description</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('Description')"></i>
                </th>
                <th v-if="hasPermissions">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="isAddingNew || isEditing && !selectedVoyage">
                <td>
                  <div v-if="nameError" class="error-message">{{ nameError }}</div>
                  <input v-model="newVoyage.Name" placeholder="Voyage Name" />
                </td>
                <td><input v-model="newVoyage.Description" placeholder="Description" /></td>
                <td v-if="hasPermissions">
                  <button @click="saveVoyage" class="save-button"><i class="fas fa-save"></i></button>
                  <button @click="cancelAddOrEdit" class="cancel-button"><i class="fas fa-times"></i></button>
                </td>
              </tr>
              <tr v-for="voyage in paginatedVoyages" :key="voyage.Voyageid">
                <template v-if="selectedVoyage && selectedVoyage.Voyageid === voyage.Voyageid">
                  <!-- Editable Row -->
                  <td>
                    <div v-if="nameError" class="error-message">{{ nameError }}</div>
                    <input v-model="newVoyage.Name" placeholder="Voyage Name" />
                  </td>
                  <td><input v-model="newVoyage.Description" placeholder="Description" /></td>
                  <td v-if="hasPermissions">
                    <button @click="saveVoyage" class="save-button"><i class="fas fa-save"></i></button>
                    <button @click="cancelAddOrEdit" class="cancel-button"><i class="fas fa-times"></i></button>
                  </td>
                </template>
                <template v-else>
                  <!-- Display Row -->
                  <td>{{ voyage.Name }}</td>
                  <td>{{ voyage.Description }}</td>
                  <td v-if="hasPermissions">
                    <button @click="editVoyage(voyage)" class="edit-button" :disabled="isAddingNew || isEditing"><i
                        class="fas fa-edit"></i></button>
                  </td>
                </template>
              </tr>

              <!-- Add or Edit New Row -->

            </tbody>
          </table>
        </div>

        <div class="bottom-controls">
          <div class="rows-per-table">
            <button v-for="option in [10, 20, 30]" :key="option" :class="{ 'active': rowsPerPage === option }"
              @click="setRowsPerPage(option)">
              {{ option }}
            </button>
          </div>
          <div class="pagination">
            <button @click="goToPage(currentPage - 1)" :disabled="!showPrevious"> &lt; </button>
            <button v-for="page in paginatedPages" :key="page" @click="goToPage(page)"
              :class="{ active: page === currentPage }">
              {{ page }}
            </button>
            <button @click="goToPage(currentPage + 1)" :disabled="!showNext"> &gt; </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import { useToast } from 'vue-toastification';
import jsPDF from 'jspdf';

export default {
  data() {
    return {
      voyages: [],
      isLoading: false,
      error: null,
      nameError: '',
      hasPermissions: false,
      searchQuery: '',
      rowsPerPage: 10,
      isEditing: false,
      isAddingNew: false,
      currentPage: 1,
      showExportOptions: false,
      selectedVoyage: null,
      newVoyage: {
        Name: '',
        Description: '',
        userid: 0,
        companyid: 0,
        isDeleted: false,
        CreateDate: new Date().toISOString(),
        ModiDate: new Date().toISOString(),
        created_by: 0,
        last_modified_by: 0,
      },
      sortDirection: {
        Name: 'asc',
        Description: 'asc',
      },
    };
  },
  created() {
    this.fetchVoyages();
    this.checkPermissions();
  },
  watch: {
    'newVoyage.Name'(newName) {
      if (this.isAddingNew || (this.isEditing && this.newVoyage.Name !== this.selectedVoyage.Name)) {
        this.checkVoyageName(newName);
      }
    },
    searchQuery() {
    // Reset to the first page when search query changes
    this.currentPage = 1;
  },
  },
  computed: {
    filteredVoyages() {
      return this.voyages.filter(voyage => {
        return Object.values(voyage).some(value =>
          String(value).toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }).sort((a, b) => {
        for (const key in this.sortDirection) {
          if (a[key] < b[key]) return this.sortDirection[key] === 'asc' ? -1 : 1;
          if (a[key] > b[key]) return this.sortDirection[key] === 'asc' ? 1 : -1;
        }
        return 0;
      });
    },
    paginatedVoyages() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.filteredVoyages.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredVoyages.length / this.rowsPerPage);
    },
    paginatedPages() {
      const totalPages = this.totalPages;
      const currentPage = this.currentPage;
      const maxPagesToShow = 10;
      let pages = [];

      if (totalPages <= maxPagesToShow) {
        pages = Array.from({ length: totalPages }, (_, i) => i + 1);
      } else {
        pages.push(1);
        let start = Math.max(2, currentPage - 4);
        let end = Math.min(totalPages - 1, currentPage + 4);

        if (start > 2) {
          pages.push('...');
        }

        for (let i = start; i <= end; i++) {
          pages.push(i);
        }

        if (end < totalPages - 1) {
          pages.push('...');
        }

        if (totalPages > 1) {
          pages.push(totalPages);
        }
      }

      return pages;
    },
    showPrevious() {
      return this.currentPage > 1;
    },
    showNext() {
      return this.currentPage < this.totalPages;
    },
  },
  methods: {
    goToPage(page) {
      if (page === '...' || page === this.currentPage) return;
      this.currentPage = page;
    },
    async checkVoyageName(name) {
      if (name.trim() === '') {
        this.nameError = '';
        return;
      }

      try {
        const voyageExists = this.voyages.some(voyage => voyage.Name.toLowerCase() === name.toLowerCase());
        this.nameError = voyageExists ? 'Voyage name already exists.' : '';
      } catch (err) {
        console.error('Error checking voyage name:', err);
      }
    },
    toggleExportOptions() {
      this.showExportOptions = !this.showExportOptions;
      document.addEventListener('click', this.handleOutsideClick);
    },
    handleOutsideClick(event) {
      if (!event.target.closest('.export-container')) {
        this.showExportOptions = false;
        document.removeEventListener('click', this.handleOutsideClick);
      }
    },
    getCurrentUserId() {
      const user = JSON.parse(localStorage.getItem('user'));
      return user ? user.user_id : 0;
    },
    async fetchVoyages() {
      this.isLoading = true;
      this.error = null;

      const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = 'No token found in localStorage';
        this.isLoading = false;
        return;
      }

      try {
        const config = {
          headers: { 
            'accept': 'application/json',
            'Authorization': `Bearer ${token}` },
        };

        const response = await axios.get('/voyage_list', config);
        this.voyages = response.data[2];
        localStorage.setItem('voyage', JSON.stringify(response.data[2]));
      } catch (error) {
        console.error('Error fetching voyage list:', error);
        this.error = 'Error fetching voyage list. Check console for details.';
      } finally {
        this.isLoading = false;
      }
    },
    checkPermissions() {
      const token = localStorage.getItem('access_token');
      this.hasPermissions = !!token; // Assuming that having a token means permissions are granted
    },
    openAddOverlay() {
      this.isAddingNew = true;
      this.selectedVoyage = null;
      this.resetNewVoyage();
      this.isEditing = false;
    },
    cancelAddOrEdit() {
      this.isAddingNew = false;
      this.selectedVoyage = null;
      this.isEditing = false;
      this.resetNewVoyage();
    },
    async saveVoyage() {
      const toast = useToast();
      if (this.nameError !== '') {
        toast.error('Please fix the errors before saving.');
        return;
      }

      try {
        const token = localStorage.getItem('access_token');
        if (!token) {
          console.error('No token found in localStorage');
          return;
        }

        const config = {
          headers: { 
            'accept': 'application/json',
            'Authorization': `Bearer ${token}` },
        };
        this.newVoyage.isDeleted = Boolean(this.newVoyage.isDeleted);
        if (this.isEditing) {
          await axios.put(`/update_voyage/${this.selectedVoyage.Voyageid}/`, this.newVoyage, config);
          toast.success('Voyage updated successfully!');
        } else {
          await axios.post('/create_voyage', this.newVoyage, config);
          toast.success('Voyage created successfully!');
        }

        this.isAddingNew = false;
        this.isEditing = false;
        this.selectedVoyage = null;
        this.fetchVoyages(); // Refresh the list after adding or editing
      } catch (error) {
        console.error('Error saving voyage:', error);
      }
    },
    editVoyage(voyage) {
      this.selectedVoyage = voyage;
      this.newVoyage = { ...voyage };
      this.isAddingNew = false;
      this.isEditing = true;
    },
    resetNewVoyage() {
      this.newVoyage = {
        Name: '',
        Description: '',
        userid: 0,
        companyid: 0,
        isDeleted: false,
        CreateDate: new Date().toISOString(),
        ModiDate: new Date().toISOString(),
        created_by: 0,
        last_modified_by: 0,
      };
    },
    setRowsPerPage(option) {
      this.rowsPerPage = option;
      this.currentPage = 1; // Reset to first page when changing rows per page
    },
    sortByColumn(column) {
      this.sortDirection[column] = this.sortDirection[column] === 'asc' ? 'desc' : 'asc';
    },
    exportToCSV() {
      const data = this.filteredVoyages;
      if (data.length === 0) {
        useToast().info('No data available to export');
        return;
      }

      const csvContent = [
        Object.keys(data[0]).join(','), // headers
        ...data.map(item => Object.values(item).join(',')), // data rows
      ].join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'voyages.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    exportToPDF() {
      const doc = new jsPDF();
      const header = 'Exported Voyage Data';
      doc.text(header, 10, 10);

      let yOffset = 20;
      this.filteredVoyages.forEach(item => {
        doc.text(`Voyage Name: ${item.Name}`, 10, yOffset);
        doc.text(`Description: ${item.Description}`, 10, yOffset + 10);
        yOffset += 20; // Increase yOffset to create space between entries
      });

      doc.save('voyages.pdf');
      this.showExportOptions = false; // Close the dropdown after action
    },
  },
};
</script>




<style scoped>

</style>