<template>
  <div class="main">
    <div class="body-content">

      <div v-if="isLoading">Loading Vessel list...</div>
      <div v-else-if="error">Error loading Vessel list: {{ error }}</div>

      <div v-else>
        <div class="controls">
          <div class="search-container">
            <input v-model="searchQuery" placeholder="Search..." />
            <i class="fas fa-search search-icon"></i>
          </div>
          <h1 class="title">Vessel List</h1>
          <div class="actions">
            <div class="tooltip-container" @mouseenter="showTooltip" @mouseleave="hideTooltip">
              <button class="add-button" @click="openAddOverlay" title="Add New Vessel" :disabled="selectedVessel">
                <i class="fas fa-plus"></i>
                <div v-if="showAddTooltip" class="tooltip">Add New Vessel</div>
              </button>
            </div>
            <div class="export-container">
              <button class="export-button" @click="toggleExportOptions" title="Export">
                <i class="fas fa-ellipsis-v"></i>
              </button>
              <ul v-if="showExportOptions" class="export-options">
                <li @click="exportToCSV">Export to CSV</li>
                <li @click="exportToPDF">Export to PDF</li>
              </ul>
            </div>


          </div>
        </div>

        <div class="table-container">
          <table class="vessel-table table-design">
            <thead>
              <tr>
                <th>
                  <span>Vessel Name</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('Name')"></i>
                </th>
                <th>
                  <span>Description</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('Description')"></i>
                </th>
                <th>
                  <span>Call Sign</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('txtColsign')"></i>
                </th>
                <th v-if="hasPermissions">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="isAddingNew" class="new-row">
                <td>
                  <div v-if="nameError" class="error-message">{{ nameError }}</div>
                  <input v-model="newVessel.Name" placeholder="Vessel Name" />
                </td>
                <td><input v-model="newVessel.Description" placeholder="Description" /></td>
                <td><input v-model="newVessel.txtColsign" placeholder="Colsign" /></td>
                <td v-if="hasPermissions">
                  <button @click="saveVessel" class="save-button"><i class="fas fa-save"></i></button>
                  <button @click="cancelAdd" class="cancel-button"><i class="fas fa-times"></i></button>
                </td>
              </tr>
              <tr class="new-row" v-for="(vessel) in paginatedVessels" :key="vessel.Vesselid"
                :class="{ 'editing': vessel === selectedVessel }">
                <td v-if="vessel === selectedVessel">
                  <div v-if="nameError" class="error-message">{{ nameError }}</div>
                  <input v-model="newVessel.Name" placeholder="Vessel Name" />
                </td>
                <td v-else>{{ vessel.Name }}</td>
                <td v-if="vessel === selectedVessel">
                  <input v-model="newVessel.Description" placeholder="Description" />
                </td>
                <td v-else>{{ vessel.Description }}</td>
                <td v-if="vessel === selectedVessel">
                  <input v-model="newVessel.txtColsign" placeholder="Colsign" />
                </td>
                <td v-else>{{ vessel.txtColsign }}</td>
                <td v-if="hasPermissions">
                  <button v-if="vessel === selectedVessel" @click="saveVessel(vessel)" class="save-button"><i
                      class="fas fa-save"></i></button>
                  <button v-if="vessel === selectedVessel" @click="cancelEdit" class="cancel-button"><i
                      class="fas fa-times"></i></button>
                  <button v-else @click="editVessel(vessel)" class="edit-button"><i class="fas fa-edit"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="bottom-controls">
          <div class="rows-per-table">
            <button v-for="option in [10, 20, 30]" :key="option" :class="{ 'active': rowsPerPage === option }"
              @click="setRowsPerPage(option)">
              {{ option }}
            </button>
          </div>
          <div class="pagination">
            <button @click="goToPage(currentPage - 1)" :disabled="!showPrevious"> &lt; </button>
            <button v-for="page in paginatedPages" :key="page" @click="goToPage(page)"
              :class="{ active: page === currentPage }">
              {{ page }}
            </button>
            <button @click="goToPage(currentPage + 1)" :disabled="!showNext"> &gt; </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from 'vue-toastification';
import Papa from 'papaparse';
import jsPDF from 'jspdf';

export default {
  data() {
    return {
      vessels: [],
      nameError: '',
      showExportOptions: false,
      isLoading: false,
      error: null,
      hasPermissions: false,
      searchQuery: '',
      rowsPerPage: 10,
      currentPage: 1,
      isAddingNew: false,
      selectedVessel: null,
      newVessel: {
        Vesselid: 0,
        Name: '',
        Description: '',
        userid: 0,
        companyid: 0,
        txtColsign: '',
        isDeleted: false,
        CreateDate: '',
        created_by: 0,
        ModiDate: '',
        last_modified_by: 0,
      },
      sortDirection: {
        Name: 'asc',
        Description: 'asc',
        txtColsign: 'asc',
        isDeleted: 'asc',
      },
      showAddTooltip: false,
    };
  },
  created() {
    this.fetchVessels();
    this.checkPermissions();
  },
  computed: {
    resetVessel() {
      return {
        Vesselid: 0,
        Name: '',
        Description: '',
        userid: 0,
        companyid: 0,
        txtColsign: '',
        isDeleted: false,
        CreateDate: '',
        created_by: 0,
        ModiDate: '',
        last_modified_by: 0,
      };
    },
    filteredVessels() {
      return this.vessels.filter(vessel => {
        return Object.values(vessel).some(value =>
          String(value).toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }).sort((a, b) => {
        for (const key in this.sortDirection) {
          if (a[key] < b[key]) return this.sortDirection[key] === 'asc' ? -1 : 1;
          if (a[key] > b[key]) return this.sortDirection[key] === 'asc' ? 1 : -1;
        }
        return 0;
      });
    },
    paginatedVessels() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.filteredVessels.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredVessels.length / this.rowsPerPage);
    },
    paginatedPages() {
    const totalPages = this.totalPages;
    const currentPage = this.currentPage; // assuming you have a currentPage data property
    const maxPagesToShow = 10;
    let pages = [];

    if (totalPages <= maxPagesToShow) {
      // Show all pages if they are fewer than the maxPagesToShow
      pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      // Always show the first page
      pages.push(1);

      // Show a range around the current page
      let start = Math.max(2, currentPage - 4);
      let end = Math.min(totalPages - 1, currentPage + 4);

      if (start > 2) {
        pages.push('...');
      }

      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      if (end < totalPages - 1) {
        pages.push('...');
      }

      // Always show the last page
      if (totalPages > 1) {
        pages.push(totalPages);
      }
    }

    return pages;
  },
  showPrevious() {
    return this.currentPage > 1;
  },
  showNext() {
    return this.currentPage < this.totalPages;
  }
  },
  watch: {
    'newVessel.Name'(newName) {
      if (this.isAddingNew || (this.selectedVessel && this.newVessel.Name !== this.selectedVessel.Name)) {
        this.checkVesselName(newName);
      }
    },
    searchQuery() {
    // Reset to the first page when search query changes
    this.currentPage = 1;
  },
  },
  methods: {
    goToPage(page) {
    if (page === '...' || page === this.currentPage) return;
    this.currentPage = page;
  },
    showTooltip() {
      this.showAddTooltip = true;
    },
    
    hideTooltip() {
      this.showAddTooltip = false;
    },
    async checkVesselName(name) {
      if (name.trim() === '') {
        this.nameError = '';
        return;
      }
      
      try {
        const vesselExists = this.vessels.some(vessel => vessel.Name.toLowerCase() === name.toLowerCase());
        if (vesselExists) {
          this.nameError = 'Vessel name already exists.';
        } else {
          this.nameError = '';
        }
      } catch (err) {
        console.error('Error checking vessel name:', err);
      }
    },
    getCurrentUserId() {
      const user = JSON.parse(localStorage.getItem('user'));
      return user ? user.user_id : 0;
    },
    async fetchVessels() {
      this.isLoading = true;
      this.error = null;

      const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = 'No token found in localStorage';
        this.isLoading = false;
        return;
      }

      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };

        const response = await axios.get('/vessel_list', config);
        this.vessels = response.data[2];
        localStorage.setItem('vessel', JSON.stringify(response.data[2]));
      } catch (error) {
        console.error('Error fetching vessel list:', error);
        this.error = 'Error fetching vessel list. Check console for details.';
      } finally {
        this.isLoading = false;
      }
    },
    async checkPermissions() {
      this.hasPermissions = true; // Set this according to your logic
    },
    openAddOverlay() {
      if (this.selectedVessel) {
        this.cancelEdit(); // Cancel editing if a vessel is currently selected
      }
      this.isAddingNew = true; // Open add overlay
      this.newVessel = { ...this.resetVessel }; // Reset new vessel fields
    },
    async saveVessel() {
      const toast = useToast();
      if (this.nameError !== '') {
        toast.error('Please fix the errors before saving.');
        return;
      }

      const token = localStorage.getItem('access_token');
      const userId = this.getCurrentUserId();
      const now = new Date().toISOString();

      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };

        if (this.selectedVessel) {
          this.newVessel.ModiDate = now;
          this.newVessel.last_modified_by = userId;
          this.newVessel.isDeleted = Boolean(this.newVessel.isDeleted);
          await axios.put(`/update_vessel/${this.selectedVessel.Vesselid}`, this.newVessel, config);
          toast.success('Vessel updated successfully!');
        } else {
          this.newVessel.CreateDate = now;
          this.newVessel.created_by = userId;
          this.newVessel.ModiDate = now;
          this.newVessel.last_modified_by = userId;

          await axios.post('/create_vessel', this.newVessel, config);
          toast.success('Vessel created successfully!');
        }

        this.fetchVessels();
        this.isAddingNew = false;
        this.selectedVessel = null;
        this.newVessel = { ...this.resetVessel };
      } catch (error) {
        console.error('Error saving vessel:', error);
        alert('Error saving vessel. Check console for details.');
      }
    },
    cancelAdd() {
      this.isAddingNew = false;
      this.newVessel = { ...this.resetVessel };
    },
    editVessel(vessel) {
      if (this.isAddingNew) {
        this.cancelAdd(); // Cancel adding if currently adding
      }
      this.selectedVessel = vessel;
      this.newVessel = { ...vessel };
    },
    cancelEdit() {
      this.selectedVessel = null;
      this.newVessel = { ...this.resetVessel };
    },
    setRowsPerPage(count) {
      this.rowsPerPage = count;
    },
    sortByColumn(column) {
      this.sortDirection[column] = this.sortDirection[column] === 'asc' ? 'desc' : 'asc';
    },
    toggleExportOptions() {
      this.showExportOptions = !this.showExportOptions;
      //console.log(this.showExportOptions);
      document.addEventListener('click', this.handleOutsideClick);
    },
    handleOutsideClick(event) {
      if (!event.target.closest('.export-container')) {
        this.showExportOptions = false;
        document.removeEventListener('click', this.handleOutsideClick);
      }
    },
    exportToCSV() {
      const csv = Papa.unparse(this.filteredVessels);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'vessels.csv';
      link.click();
      this.showExportOptions = false; // Close the dropdown after action
    },
    exportToPDF() {
      const doc = new jsPDF();
      const header = 'Exported Vessel Data';
      doc.text(header, 10, 10);
      
      let yOffset = 20;
      this.filteredVessels.forEach(item => {
        doc.text(`Name: ${item.Name}, Description: ${item.Description}, Call Sign: ${item.txtColsign}`, 10, yOffset);
        yOffset += 10;
      });
      
      doc.save('vessels.pdf');
      this.showExportOptions = false; // Close the dropdown after action
    }
  },
};
</script>

<style scoped>

</style>