<template>
  <div class="body-content">
    <h1 class="title">Dashboard</h1>
    <!--<add-inspection-grid ></add-inspection-grid>
    <add-damage save="saves" v-on:save-clicked="increment"></add-damage>-->
  </div>

</template>
<script>
import axios from 'axios';
//import AddDamage from '/src/components/AddDamage.vue';
//import AddInspectionGrid from '/src/components/AddInspectionGrid.vue';
export default {
  components: {
    //AddDamage,
    //AddInspectionGrid,
  },
  created() {
    this.fetchStates();
    this.fetchCountries();
    this.fetchBanks();
    this.fetchCFS();
    this.fetchLocations();
    this.fetchVessels();
    this.fetchVoyages();
    this.fetchApplicants();
    this.fetchAccounts();
    this.fetchLinkedParty();
    this.fetchMovements();
  },
  methods: {
    increment(damagedata) {
      alert("clicked")
      console.log(damagedata);
    },
    
    async fetchMovements() {
      const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = 'No token found in localStorage';
        return;
      }

      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };
        const response = await axios.get('/movement_list', config);        
        var data=response.data[2]  
        data.push({Movid :0,Name :'Select movement'  })
        localStorage.setItem('movements', JSON.stringify(data));
        console.log(localStorage.getItem('movements'))

      } catch (error) {
        console.error('Error fetching movement list:', error);
      } 
    },
    async fetchStates() {
      try {
        const response = await axios.get('/states');
        //this.states = response.data[2];
        //console.log(response.data[2])
        var data=response.data[2]
        //console.log(data)
        //data.push({stateId:0,stateName:'Select State'  })
        //console.log(data)
        localStorage.setItem('states', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching states:', error);
      } finally {
        //console.log(localStorage.getItem('states'))
      }
    },
    async fetchCountries() {
      try {
        const response = await axios.get('/countries');
        //console.log(response.data[2])
        localStorage.setItem('countries', JSON.stringify(response.data[2]));
      } catch (error) {
        console.error('Error fetching countries:', error);
      } finally {
        //console.log(localStorage.getItem('countries'))
      }
    },
    async fetchCFS() {
      try {
        const response = await axios.get('/cfs_locations');
        var data=response.data[2]  
        data.push({locationid :0,Name	:'Select cfs/icd',ShortName :'Select cfs/icd' })
        localStorage.setItem('cfs_location', JSON.stringify(data));
        
      } catch (error) {
        console.error('Error fetching countries:', error);
      } finally {
        //console.log(localStorage.getItem('cfs_location'))
      }
    },
    async fetchBanks() {
      const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = 'No token found in localStorage';
        this.isLoading = false;
        return;
      }
      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };
        const response = await axios.get('/banks', config);
        var data=response.data[2]  
        data.push({autoid :0,BankName	:'Select banks' })
        localStorage.setItem('banks', JSON.stringify(data));

      } catch (error) {
        console.error('Error fetching banks:', error);
      } finally {
        //console.log(localStorage.getItem('banks'))
      }
    },
    async fetchLocations() {
      const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = 'No token found in localStorage';
        return;
      }
      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };
        const response = await axios.get('/location_list', config);
        var data=response.data[2]  
        data.push({locationid :0,Name	:'Select location',ShortName :'Select location'  })
        localStorage.setItem('locations', JSON.stringify(data));

      } catch (error) {
        console.error('Error fetching location list:', error);
      } finally {
        //console.log(localStorage.getItem('locations'))
      }
    },
    async fetchVessels() {
      const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = 'No token found in localStorage';
        this.isLoading = false;
        return;
      }
      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };
        const response = await axios.get('/vessel_list', config);
        var data=response.data[2]  
        data.push({Vesselid :0,Name	:'Select vessel'  })
        localStorage.setItem('vessel', JSON.stringify(data));

      } catch (error) {
        console.error('Error fetching vessel list:', error);
      } finally {
        //console.log(localStorage.getItem('vessel'))
      }
    },
    async fetchVoyages() {
      const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = 'No token found in localStorage';
        return;
      }
      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        };
        const response = await axios.get('/voyage_list', config);  
        var data=response.data[2]  
        data.push({Voyageid :0,Name	:'Select voyage'  })
        localStorage.setItem('voyage', JSON.stringify(data));
        
      } catch (error) {
        console.error('Error fetching voyage list:', error);
      } finally {
        //console.log(localStorage.getItem('voyage'))
      }
    },
    async fetchApplicants() {
      const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = 'No token found in localStorage';
        return;
      }
      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };
        const response = await axios.get('/applicant_list/', config);          
        var data=response.data[2]  
        data.push({applicantid :0,applicantname:'Select applicant'  })
        localStorage.setItem('applicants', JSON.stringify(data));
        
      } catch (error) {
        console.error('Error fetching applicant list:', error);
      } finally {
        //console.log(localStorage.getItem('applicants'))
      }
    },
    async fetchAccounts() {
      const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = 'No token found in localStorage';
        return;
      }
      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };
        const response = await axios.get('/accounts/', config);           
        var data=response.data[2]  
        data.push({accountid :0,accountname:'Select account'  })
        localStorage.setItem('accounts', JSON.stringify(data));
        
      } catch (error) {
        console.error('Error fetching accounts:', error);
      } finally {
        //console.log(localStorage.getItem('accounts'))
      }
    },
    async fetchLinkedParty() {
      const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = 'No token found in localStorage';
        return;
      }
      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };
        const response = await axios.get('/linkParties', config);      
        var data=response.data[2]  
        data.push({autoid :0,short_name:'Select Party'  })
        localStorage.setItem('party', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching party:', error);
      } finally {
        //console.log(localStorage.getItem('party'))
      }
    }
  }
}

</script>


<style scoped>
.dashboard {
  padding: 20px;
}

.widgets {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
</style>
